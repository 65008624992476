import { BREAKPOINT_SMALL } from '@big-red-group/storefront-common-ui'
import { useState, useEffect } from 'react'

export const isMobile = () => typeof window !== 'undefined' && window.innerWidth < BREAKPOINT_SMALL

export const useIsMobile = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile())

  useEffect(() => {
    const handleResize = () => setIsMobileView(isMobile())

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobileView
}
