import styled from 'styled-components'
import { Grid } from './ListingPreview.styles'
import { Wrapper as SelectWrapper } from '@/components/Select.styles'
import { BREAKPOINT_MEDIUM_LARGE, BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'
import { Wrapper as FakeCheckbox } from '@/components/FakeCheckbox.styles'

export const NoResultsWrapper = styled.div`
  text-align: center;
  padding: 30px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--brand-accent-secondary-dark);
  font-size: 17px;
  box-sizing: border-box;
`

export const GridLeft = styled.div<{ topBarHeight: number }>`
  width: 260px;
  flex-shrink: 0;
  position: sticky;
  top: 100px;
  left: 0px;
  z-index: ${Z_INDEX.base};
  max-height: calc(100vh - 120px);
  overflow: auto;
  border-radius: 4px;
  border: 1px solid var(--brand-secondary);
  opacity: 0;
  transition: 0.1s ease-in-out;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    position: fixed;
    max-height: unset;
    overflow: unset;
    top: 100%;
    z-index: ${Z_INDEX.high};
    left: 0;
    width: 100%;
    height: calc(100vh - ${({ topBarHeight }) => topBarHeight + 30}px);
    background: white;
    box-sizing: border-box;
    border-radius: 0;
    border: 0;
    opacity: 1;
    transition: 0.35s ease-in-out;
  }
`
export const GridRight = styled.div`
  width: 100%;
  z-index: ${Z_INDEX.low};
  background: white;
  position: absolute;
  right: 0;
  transition: 0.3s ease-in-out;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    position: static;
  }
`

export const ProductsHeadingWrapper = styled.div`
  margin-bottom: 16px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 4px;
  }
`

export const StickyFiltersWrapper = styled.div<{ isSticky: boolean; topBarHeight: number }>`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    box-shadow: ${({ isSticky }) => (isSticky ? '0 4px 8px rgba(0, 0, 0, 0.08)' : 'none')};
    position: sticky;
    top: ${({ topBarHeight }) => `${topBarHeight}px`};
    width: 100vw;
    padding: 16px 0 0;
    margin-left: -16px;
    z-index: ${Z_INDEX.midHigh};
    background: white;
    right: 0;
    box-sizing: border-box;
    contain: layout paint; /* Optimization: Stops unnecessary browser repaints / flickers */
  }
`

export const FilterTriggerBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
  transition: transform 0.3s ease-in-out;

  ${SelectWrapper} {
    margin-left: auto;
    flex-shrink: 0;

    select {
      padding: 10px 18px;
      width: 200px;
      height: 48px;
      font-size: 16px;
      line-height: 17px;
      font-family: var(--font-regular);
      color: unset;
      text-align: inherit;
      position: relative;
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 16px;
    z-index: ${Z_INDEX.medium + 1};
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
    overscroll-behavior-x: none;
    &::-webkit-scrollbar {
      display: none;
    }

    ${SelectWrapper} {
      display: none;
    }
  }
`

export const ResultsCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  gap: 8px;

  .sort-icon {
    display: none;
    gap: 8px;
    position: relative;
    align-items: center;
    white-space: nowrap;

    & > svg {
      width: 16px;
      flex-shrink: 0;
    }
    ${SelectWrapper} {
      opacity: 0;
      position: absolute;
      width: 100%;
      > select {
        padding: 0;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    margin-top: 0;
    padding: 16px 16px 16px;
    .sort-icon {
      display: flex;
      font-size: 14px;
    }
  }

  @media (max-width: 400px) {
    .showing-text {
      display: none;
    }
    .sort-label {
      font-size: 14px;
    }
  }
`
export const ResultsCount = styled.div`
  width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 0;
    font-size: 14px;
  }
`

export const SpacingWrapper = styled.div`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`

export const MobileFilterHeader = styled.div<{ isAllFilters?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  padding: 18px;
  border-bottom: 1px solid var(--grey-10);
  ${({ isAllFilters }) =>
    isAllFilters &&
    `
    position: sticky;

    &:before {
      background-color: var(--grey-50);
      border-radius: 2px;
      content: "";
      height: 4px;
      left: calc(50% - 20px);
      position: absolute;
      top: 6px;
      width: 40px;
    }
  `}
  top: 0;
  z-index: ${Z_INDEX.high + 1};
  background: white;
  @media (min-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`
export const Close = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`
export const FilterDropdownWrap = styled.div`
  position: relative;
`
export const FilterDropdownButton = styled.button<{ filterOpen: boolean }>`
  position: relative;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  height: 48px;
  padding: 0 18px;
  border: 1px solid var(--brand-accent-secondary-dark);
  color: var(--text-primary);

  &:hover {
    border-color: var(--brand-secondary);
  }

  svg {
    width: 11px;
    height: 11px;
  }

  &.all-filters {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &:not(.all-filters) {
    min-width: 150px;
    box-sizing: border-box;

    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      border-right: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: rotate(45deg);
      position: relative;
      top: -2px;
      flex-shrink: 0;
      transition: transform 0.25s ease-in-out;
    }
  }

  ${({ filterOpen }) =>
    filterOpen &&
    `
    background: var(--brand-secondary);
    border-color: var(--brand-secondary);
    color: var(--text-inverse);
    
    &:not(.all-filters)::after {
      transform: rotate(-135deg);
      top: 2px;
    }
  `}

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    &:not(.all-filters) {
      min-width: unset;
      gap: 10px;
      font-size: 14px;
      height: 40px !important;
    }

    &.all-filters {
      gap: 0;
      padding: 0 10px !important;
      height: 40px !important;
      display: flex;
      justify-content: center;

      p {
        font-size: 0;
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`
export const FilterDropdownButtonLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;

  p {
    color: inherit;
  }
`
export const FilterDropdownPanel = styled.div<{ active: boolean }>`
  padding: 15px;
  background: white;
  border: 1px solid var(--brand-accent-secondary-dark);
  position: absolute;
  top: calc(100% + 10px);
  z-index: ${Z_INDEX.medium + 1};
  border-radius: 4px;
  width: 240px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  max-height: 400px;
  overflow: auto;
  opacity: 0;
  transition:
    top 0.35s ease-in-out,
    opacity 0.2s ease-in-out;
  pointer-events: none;
  ${({ active }) =>
    active &&
    `
    opacity: 1;
    pointer-events: all;
    border-color: var(--brand-secondary);
  `}

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: block;
    position: fixed;
    border: 0;
    left: 0;
    z-index: ${Z_INDEX.high};
    padding: 0;
    width: 100%;
    height: 50%;
    max-height: unset;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: none;
    overflow: unset;
    padding-bottom: 70px;
    top: 100%;
    opacity: 0;
    transition:
      top 0.35s ease-in-out,
      opacity 0.2s ease-in-out;
    pointer-events: none;
    ${({ active }) =>
      active &&
      `
      top: 50%; 
      opacity: 1;
      pointer-events: all;
    `}
  }
`

export const FilterBg = styled.div<{ active: boolean }>`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  background: black;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.high};
  pointer-events: none;
  transition: 0.35s ease-in-out;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: block;
    ${({ active }) =>
      active &&
      `
      opacity: 0.5;
      pointer-events: all;
    `}
  }
`

export const PanelContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 18px;
    overflow: auto;
    flex-grow: 2;
    height: fit-content;
    max-height: calc(100% - 72px);
    box-sizing: border-box;
  }
`
export const TagFilters = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`
export const PricingButtons = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  gap: 8px;
`
export const PriceFilter = styled.div<{ isActive: boolean }>`
  border: 1px solid var(--brand-accent-secondary-dark);
  text-align: center;
  padding: 8px 5px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;

  &:hover {
    border: 1px solid var(--brand-secondary);
  }

  ${({ isActive }) =>
    isActive &&
    `
    background: var(--brand-primary);
    border-color: var(--brand-primary);
    color: var(--brand-secondary);
  `}
`
export const CheckboxFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 16px;
`
export const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;

  ${FakeCheckbox} {
    flex-grow: 2;
    cursor: pointer;
  }
`
export const GroupHeaderToggle = styled.div<{ active: boolean; openByDefault?: boolean }>`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--grey-100);
  transition: all 0.15s ease-in-out 0s;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
    color: var(--text-primary);
    transition: all 0.15s ease-in-out 0s;
  }

  &:hover {
    border-color: var(--grey-100);
  }

  ${({ active }) =>
    active &&
    `
    background: var(--grey-25);
    border-color: var(--grey-25);

    svg {
      transform: rotate(180deg);
    }
  `}

  ${({ openByDefault }) =>
    openByDefault &&
    `
      background: transparent;
      border-color: transparent;
      width: 24px;
      height: 24px;
      transform: rotate(180deg);

      svg {
        transform: rotate(0deg);
        width: 13px;
        height: 13px;
      }
  `}
  ${({ openByDefault, active }) =>
    openByDefault &&
    !active &&
    `
      svg {
        transform: rotate(180deg);
        width: 13px;
        height: 13px;
      }
  `}
`
export const GroupSublinks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 10px;
  padding-left: 20px;

  label {
    font-size: 14px;
  }
`
export const FilterIndicator = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--brand-accent-dark);
  color: var(--text-inverse);
  border-radius: 50%;
  font-family: var(--font-medium);
  margin-left: 5px;
`

export const FloatingFilterBtn = styled.div<{ active: boolean }>`
  background: var(--brand-accent);
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 42px;
  height: 42px;
  z-index: ${Z_INDEX.medium + 1};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
  transform: scale(0);
  transition: 0.4s cubic-bezier(0.3, 0.87, 0.63, 1.23);

  svg {
    width: 24px;
    height: 24px;
    color: var(--text-inverse);
  }

  ${({ active }) =>
    active &&
    `
    pointer-events: all;
    transform: scale(1);
  `}
`

export const ListingWrap = styled.div<{ active: boolean; activeMinHeight: number; topBarHeight: number }>`
  min-height: unset;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.3s ease-in-out;
  gap: 25px;
  position: relative;

  @media (min-width: ${BREAKPOINT_SMALL}px) {
    min-height: ${({ activeMinHeight }) => activeMinHeight}px;
  }

  ${({ active, topBarHeight }) =>
    active &&
    `
    @media (min-width: 769px) {
      .product-tile__image {
        height: 184px;
      }
      .product-tile__badges {
        top: 170px !important;
      }
      .product-tile__hot-deal {
        top: 158px;
      }
      .product-tile:nth-child(10n - 1), .product-tile:nth-child(10n) {
        grid-column-end: span 1;

        .product-tile__image {
          height: 184px;
        }
      }
    }

    ${GridLeft} {
      opacity: 1;
    }
    
    @media (min-width: 1201px) {
      ${Grid} {
        grid-template-columns: repeat(3,minmax(0,calc(100% / 3)));
      }
    }
    
    @media (min-width: 769px) and (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
      ${Grid} {
        grid-template-columns: repeat(2, minmax(0,50%));
      }
    }

    @media (min-width: 600px) {
      ${GridRight} {
        width: calc(100% - 280px);
      }
    }

    @media (max-width: ${BREAKPOINT_SMALL}px) {
      ${GridLeft} {
        top: ${topBarHeight + 30}px;
      }
    }
  `}
`
