import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import { Body, FlexibleHeading as Heading, Spacing } from '@big-red-group/storefront-common-ui'
import { isEqual } from 'lodash'
import {
  FilterDropdown,
  FiltersListingPreviewEntityType,
  PlpProduct,
  SearchFilterDecorator,
  SortBy,
  SortByName,
  WidgetCategoryLink,
  WidgetRegionLink,
} from '@/types'
import { FILTER_DROPDOWNS, PRICING_FILTERS } from '@/constant/plp'
import { BASE_MOBILE_TOP_BAR_HEIGHT, HAPPY_HOUR_BANNER_HEIGHT } from '@/constant/ui'
import Icon from '../Icons'
import Button from '../Button'
import ListingFilters from '../ListingFilters'
import { sortProducts } from '@/helpers/SearchHelper'
import ListingPreview from './ListingPreview'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'
import { filterHeading, renderCategoryFilters, renderPriceFilters, renderRegionFilters } from '@/helpers/FilterHelper'
import { Footer, FooterButtons } from '../ListingFilters.styles'
import {
  Close,
  FilterBg,
  FilterDropdownButton,
  FilterDropdownButtonLabel,
  FilterDropdownPanel,
  FilterDropdownWrap,
  FilterIndicator,
  FilterTriggerBar,
  FloatingFilterBtn,
  GridLeft,
  GridRight,
  ListingWrap,
  MobileFilterHeader,
  NoResultsWrapper,
  PanelContent,
  ProductsHeadingWrapper,
  ResultsCount,
  ResultsCountWrapper,
  SpacingWrapper,
  StickyFiltersWrapper,
} from '@/components/widgets/FiltersListingPreview.styles'
import { ViewMore, ViewMoreWrapper } from '@/components/widgets/ListingPreview.styles'
import Select from '../Select'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'
import { usePricingValues } from '@/hooks/FiltersHook'
import { ParsedUrlQuery } from 'querystring'
import { scrollToElement } from '@/helpers/UtilHelper'
import { createPortal } from 'react-dom'
import { useIsMobile } from '@/hooks/UseIsMobile'
import { useDefaultProperties } from '@/hooks/UseDefaultPropertiesHook'

type FiltersListingPreviewProps = {
  entityType: FiltersListingPreviewEntityType
  products: PlpProduct[]
  regions: WidgetRegionLink[] | undefined
  categories: WidgetCategoryLink[] | undefined
  title?: string
  handlerKey?: string
  hideCount?: boolean
  changeUrl?: boolean
}

const sortOptions = [
  { name: 'popular', label: 'Popular' },
  { name: 'priceLow', label: 'Price (Low to High)' },
  { name: 'priceHigh', label: 'Price (High to Low)' },
  { name: 'ratingHigh', label: 'Rating (High to Low)' },
  { name: 'alphabeticallyHigh', label: 'A-Z' },
  { name: 'alphabeticallyLow', label: 'Z-A' },
]

let pageSize = 17

const expandedCategoryEntities: FiltersListingPreviewEntityType[] = ['THINGS_TO_DO', 'CATEGORY']

const FilterListingPreview = ({
  entityType,
  title,
  products,
  handlerKey,
  regions,
  categories,
  hideCount,
  changeUrl,
}: FiltersListingPreviewProps) => {
  const isDesktop = useMediaQuery({ minWidth: 901 })
  const isXL = useMediaQuery({ minWidth: 1200 })
  const initialFilterState = useRef(false)
  const defaultProperties = useDefaultProperties()
  const happyHour = !!defaultProperties?.happyHourEvent
  // Adjust MOBILE_TOP_BAR_HEIGHT based on happyHour banner
  const MOBILE_TOP_BAR_HEIGHT = happyHour
    ? BASE_MOBILE_TOP_BAR_HEIGHT + HAPPY_HOUR_BANNER_HEIGHT
    : BASE_MOBILE_TOP_BAR_HEIGHT

  const getInitialCatsFromUrl = () => {
    let res: string[] = []
    const ids = `${param.category || ''}`.split(',').filter((a: string) => a) as string[]
    ids.forEach((id) => {
      categories?.forEach((a) => {
        if (`${a.id}` === id) {
          res.push(a.link)
        }
        res = res.concat((a.subcategories || []).filter((b) => `${b.id}` === id).map((c) => c.link))
      })
    })
    return res
  }

  const getInitialRegsFromUrl = (queryParams: ParsedUrlQuery): string[] => {
    return Array.isArray(queryParams.region)
      ? queryParams.region
      : queryParams.region
        ? queryParams.region.split(',')
        : []
  }

  const router = useRouter()
  const param = router.query
  const [originalResults, setOriginalResults] = useState<PlpProduct[]>(products)
  const [results, setResults] = useState<PlpProduct[]>(products)
  const initialFilters = FILTER_DROPDOWNS.map((filter) => ({ ...filter }))
  const [filterDropdowns, setFilterDropdowns] = useState<FilterDropdown[]>(initialFilters)
  const [hotDealsEnabled, setHotDealsEnabled] = useState(param.special === 'on-sale')
  const [availableInclusions, setAvailableInclusions] = useState<SearchFilterDecorator[]>([])
  const [selectedInclusions, setSelectedInclusions] = useState<SearchFilterDecorator[]>(
    ((param.additional || '') as string).split(',').filter((a) => a) as any
  )
  const [toggleProductListViewed, setToggleProductListViewed] = useState(false)

  const [selectedSort, setSortedValue] = useState<SortByName>((param.sort || 'popular') as SortByName)
  const [priceRange, setPriceRange] = useState([
    param.price_min ? +param.price_min : 0,
    param.price_max ? +param.price_max : 9999,
  ])
  const [toggledCatGroups, setToggledCatGroups] = useState<number[]>([])
  const [selectedCatFilters, setSelectedCatFilters] = useState<string[]>(getInitialCatsFromUrl())
  const [toggledRegionGroups, setToggledRegionGroups] = useState<number[]>([])
  const [selectedRegionFilters, setSelectedRegionFilters] = useState<string[]>(getInitialRegsFromUrl(param))
  const productsRef = useRef<HTMLInputElement>(null)
  const [productContainerHeight, setProductContainerHeight] = useState(0)
  const [displayLimit, setDisplayLimit] = useState(pageSize)
  const [visibleTotalCount, setVisibleTotalCount] = useState(products.length)
  const pricingValues = usePricingValues(products)

  const [isSticky, setIsSticky] = useState(false)
  const stickyFilterBarRef = useRef<HTMLDivElement | null>(null)

  const isMobileView = useIsMobile()

  useEffect(() => {
    if (!initialFilterState.current && isDesktop) {
      const updatedFilters = filterDropdowns.map((filter, index) => ({
        ...filter,
        isOpen: index === 0, // Open first filter by default
      }))
      setFilterDropdowns(updatedFilters)
      initialFilterState.current = true
    }
  }, [isDesktop])

  useEffect(() => {
    if (!isEqual(originalResults, products)) {
      setOriginalResults(products)
      setResults(products)
      setToggledCatGroups([])
      setToggledRegionGroups([])
    }
  }, [products])

  // Open parent category toggles on selected PLP entities
  useEffect(() => {
    if (categories && expandedCategoryEntities.includes(entityType)) {
      setToggledCatGroups(categories.map((category, index) => index))
    }
  }, [categories, entityType])

  useEffect(() => {
    let values: number[] = []
    let inclusions: SearchFilterDecorator[] = []
    products?.map((product) => {
      values.push(product.fromPrice ?? 0)
      if (!product.decorators) {
        return
      }
      const productDecorators = product.decorators.map(({ name }) => name as SearchFilterDecorator)
      inclusions = [...inclusions, ...productDecorators]
    })
    setAvailableInclusions([...new Set(inclusions!)])
  }, [products])

  useEffect(() => {
    handleFilter()
    handleUrlChange()
  }, [
    priceRange,
    selectedInclusions,
    selectedSort,
    hotDealsEnabled,
    selectedRegionFilters,
    selectedCatFilters,
    displayLimit,
  ])

  const handleUrlChange = () => {
    if (changeUrl) {
      const obj: any = {}
      if (priceRange[0] !== 0 || priceRange[1] !== 9999) {
        obj.price_min = priceRange[0]
        obj.price_max = priceRange[1]
      } else {
        obj.price_min = undefined
        obj.price_max = undefined
      }
      if (selectedCatFilters && selectedCatFilters.length > 0) {
        let catIds: number[] = []
        categories?.forEach((c) => {
          if (selectedCatFilters.includes(c.link)) {
            catIds.push(c.id)
          }
          catIds = catIds.concat(
            (c.subcategories || []).filter((a) => selectedCatFilters.includes(a.link)).map((a) => a.id)
          )
        })
        obj.category = catIds.join(',')
        obj.category = obj.category || undefined
      } else {
        obj.category = undefined
      }

      if (selectedRegionFilters && selectedRegionFilters.length > 0) {
        obj.region = selectedRegionFilters.join(',')
        obj.region = obj.region || undefined
      } else {
        obj.region = undefined
      }

      if (hotDealsEnabled) {
        obj.special = 'on-sale'
      } else {
        obj.special = undefined
      }

      if (selectedInclusions && selectedInclusions.length > 0) {
        obj.additional = selectedInclusions.join(',')
      } else {
        obj.additional = undefined
      }

      if (selectedSort && selectedSort !== 'popular') {
        obj.sort = selectedSort
      } else {
        obj.sort = undefined
      }

      const query = {
        ...router.query,
        ...obj,
        sort: obj.sort,
      }
      delete query.lang
      delete query.part1
      delete query.part2
      delete query.part3
      const querystr = Object.keys(query)
        .filter((a) => query[a] !== undefined)
        .sort((a, b) => (a === 'sort' ? 1 : -1))
        .map((key) => `${key}=${query[key]}`)
        .join('&')

      const newUrl = `${window.location.pathname}${querystr ? `?${querystr}` : ''}`

      if (newUrl !== window.location.href) {
        router.push(newUrl, undefined, { shallow: true })
      }
    }
  }

  useEffect(() => {
    if (productsRef.current !== null) {
      setTimeout(() => {
        setProductContainerHeight(productsRef.current!.clientHeight)
      }, 200)
    }
  }, [results, filterDropdowns])

  useEffect(() => {
    if (!isMobileView) return // Only run on mobile

    const top = document.getElementById('stickyFiltersWrap')
    const endOfResults = document.getElementById('productsGrid')

    if (!top || !endOfResults) return
    let lastScrollY = window.scrollY
    const topPos = top.getBoundingClientRect().top + lastScrollY
    let listingBottom = endOfResults.getBoundingClientRect().bottom + lastScrollY - 50 // Stop sticky 50px earlier

    const handleScroll = () => {
      const scrollY = window.scrollY
      const scrollingUp = scrollY < lastScrollY
      const atEndOfList = scrollY >= listingBottom // Stop sticky before reaching the bottom
      const passedFilters = scrollY >= topPos // Sticky as soon as filters scroll out of view
      const awayFromEnd = scrollY < listingBottom - 100 // Prevent flicker when scrolling up

      if (atEndOfList && !scrollingUp) {
        setIsSticky(false)
      } else if (!atEndOfList && passedFilters) {
        setIsSticky(true)
      } else if (scrollingUp && awayFromEnd) {
        setIsSticky(scrollY > topPos)
      }

      lastScrollY = scrollY
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Run initially to ensure correct state

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isMobileView])

  const viewMoreProducts = () => {
    setDisplayLimit((prevLimit) => {
      const res = prevLimit + pageSize + 1
      if (pageSize === 17) {
        pageSize = 19
      }
      return res
    })
  }

  const isInitialMount = useRef(true)
  const userChangedFilter = useRef(false) // Tracks manual filter changes

  const handleFilter = () => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (userChangedFilter.current) {
      scrollToElement('triggerBar', isXL ? 160 : 120) // Scroll only when a filter is changed
    }

    userChangedFilter.current = false

    let newFilteredProducts = sortProducts({ name: selectedSort } as any, originalResults)

    if (hotDealsEnabled) {
      newFilteredProducts = newFilteredProducts?.filter(
        (product: { hotDeals: string | any[] }) => product.hotDeals && product.hotDeals.length > 0
      )
    }

    newFilteredProducts = newFilteredProducts?.filter(
      (product: { fromPrice: number }) => product.fromPrice >= priceRange[0] && product.fromPrice <= priceRange[1]
    )

    if (selectedInclusions.length > 0) {
      newFilteredProducts = newFilteredProducts?.filter((product: { decorators: { name: string }[] }) =>
        product.decorators?.some((decorator: { name: string }) =>
          selectedInclusions.includes(decorator.name as SearchFilterDecorator)
        )
      )
    }

    // Apply region filters
    if (selectedRegionFilters.length > 0) {
      newFilteredProducts = newFilteredProducts.filter((product) => {
        const productRegions = new Set<string>()

        if (product.regions) {
          product.regions.forEach((region) => {
            if (region) productRegions.add(region.toLowerCase())
          })
        }

        if (product.canonicalRegionUrlSegment) {
          productRegions.add(product.canonicalRegionUrlSegment)
        }

        const selectedRegionNames = selectedRegionFilters
          .map((filterUrl) => {
            const selectedRegion = regions?.find(
              (state) => state.link === filterUrl || state.regions?.some((r) => r.link === filterUrl)
            )
            if (selectedRegion) {
              if (selectedRegion.link === filterUrl) {
                return selectedRegion.name.toLowerCase()
              }
              return selectedRegion.regions?.find((r) => r.link === filterUrl)?.name.toLowerCase()
            }
            return ''
          })
          .filter(Boolean)

        return (
          selectedRegionNames.some((selectedRegion) => productRegions.has(selectedRegion!)) ||
          selectedRegionFilters.find((sel) => productRegions.has(sel))
        )
      })
    }

    // Apply category filters
    if (selectedCatFilters.length > 0) {
      newFilteredProducts = newFilteredProducts.filter((product) => {
        return (
          product.categoryUrlSegments?.some((segment) => selectedCatFilters.includes(segment)) ||
          product.subcategoryUrlSegments?.some((segment) => selectedCatFilters.includes(segment))
        )
      })
    }

    setVisibleTotalCount(newFilteredProducts.length)
    setResults(newFilteredProducts.slice(0, displayLimit) ?? [])
    setToggleProductListViewed(true)
  }

  const roundToNearestTen = (price: number) => {
    return roundDecimalNumber(price, 1)
  }

  const handleSort = (sortBy: string) => {
    setSortedValue(sortBy as SortByName)
  }

  const handleReset = () => {
    setSelectedInclusions([])
    setHotDealsEnabled(false)
    setPriceRange([0, 9999])
    setSelectedCatFilters([])
    setSelectedRegionFilters([])
    setResults(sortProducts({ name: selectedSort } as SortBy, originalResults)!)
    document.body.style.overflow = 'unset'
  }

  const handleOpenFilter = (filterIndex: number) => {
    toggleFilterDropdown(filterIndex)

    document.body.style.overflow = window.innerWidth < 768 ? 'hidden' : 'unset'
  }

  const handleCloseFilter = (filterIndex: number) => {
    toggleFilterDropdown(filterIndex)
    document.body.style.overflow = 'unset'
  }

  const toggleFilterDropdown = (menuIndex: number) => {
    const menuIsOpen = filterDropdowns[menuIndex].isOpen
    if (!menuIsOpen) {
      filterDropdowns.forEach((filter) => {
        if (filter.name !== 'all filters') {
          filter.isOpen = false
        }
      })
    }
    filterDropdowns[menuIndex].isOpen = !menuIsOpen
    setFilterDropdowns([...filterDropdowns])
  }

  const renderResults = () => {
    const filtered = results
    if (filtered?.length === 0 || !filtered) {
      return (
        <NoResultsWrapper>
          <Heading size="S" weight="regular" alignment="center">
            No results found.
          </Heading>
        </NoResultsWrapper>
      )
    }
    return (
      <>
        <ListingPreview
          products={results}
          toggleProductListViewed={toggleProductListViewed}
          isRecommended={false}
          handlerKey={handlerKey}
          totalProductsCount={visibleTotalCount}
          noSpacing
        />
        {results.length < visibleTotalCount && (
          <>
            <Spacing size="XL" />
            <ViewMoreWrapper>
              <ViewMore onClick={viewMoreProducts}>Load more</ViewMore>
            </ViewMoreWrapper>
          </>
        )}
        <div id="bottomPos"></div>
      </>
    )
  }

  const handleOutsideClick = () => {
    filterDropdowns.forEach((filter) => {
      if (filter.name !== 'all filters') {
        filter.isOpen = false
      }
    })
    setFilterDropdowns([...filterDropdowns])
  }

  useOutsideClick('filter-con-inside', handleOutsideClick)

  return (
    <>
      {title && (
        <>
          <ProductsHeadingWrapper id="productsHeadingWrapper">
            <Heading as="h2" size="L">
              {title}
            </Heading>
          </ProductsHeadingWrapper>
        </>
      )}
      <StickyFiltersWrapper
        id="stickyFiltersWrap"
        isSticky={isSticky}
        topBarHeight={MOBILE_TOP_BAR_HEIGHT}
        ref={stickyFilterBarRef}
      >
        <FilterTriggerBar id="triggerBar">
          <RenderFilterButtons
            entityType={entityType}
            regions={regions}
            categories={categories}
            filterDropdowns={filterDropdowns}
            setFilterDropdowns={setFilterDropdowns}
            pricingValues={pricingValues}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            toggledCatGroups={toggledCatGroups}
            setToggledCatGroups={setToggledCatGroups}
            selectedCatFilters={selectedCatFilters}
            setSelectedCatFilters={setSelectedCatFilters}
            toggledRegionGroups={toggledRegionGroups}
            setToggledRegionGroups={setToggledRegionGroups}
            selectedRegionFilters={selectedRegionFilters}
            setSelectedRegionFilters={setSelectedRegionFilters}
            handleOpenFilter={handleOpenFilter}
            handleCloseFilter={handleCloseFilter}
            toggleFilterDropdown={toggleFilterDropdown}
          />
          <Select
            name="pickup"
            options={sortOptions.map((a) => ({ label: a.label, value: a.name }))}
            onChange={handleSort}
            value={selectedSort}
            isDisabled={!products?.length}
          />
        </FilterTriggerBar>
        <ResultsCountWrapper>
          {products.length && !hideCount && (
            <ResultsCount>
              <p>{visibleTotalCount} results found</p>
            </ResultsCount>
          )}
          <div className="sort-icon">
            <Icon name="sort" />
            <span className="sort-label">{sortOptions.find((a) => a.name === selectedSort)?.label}</span>
            <Select
              name="pickup"
              options={sortOptions.map((a) => ({ label: a.label, value: a.name }))}
              onChange={handleSort}
              value={selectedSort}
              isDisabled={!products?.length}
            />
          </div>
        </ResultsCountWrapper>
      </StickyFiltersWrapper>
      <SpacingWrapper>
        <Spacing size="XL" />
      </SpacingWrapper>
      <ListingWrap
        active={filterDropdowns[0].isOpen}
        activeMinHeight={productContainerHeight}
        topBarHeight={MOBILE_TOP_BAR_HEIGHT}
        id="listingWrap"
      >
        <FilterBg active={filterDropdowns[0].isOpen} />
        <GridLeft topBarHeight={MOBILE_TOP_BAR_HEIGHT}>
          <ListingFilters
            onClose={() => handleCloseFilter(0)}
            originalResults={originalResults}
            setProducts={setResults}
            handleFilter={handleFilter}
            reset={handleReset}
            availableInclusions={availableInclusions}
            selectedInclusions={selectedInclusions}
            hotDealsEnabled={hotDealsEnabled}
            setHotDealsEnabled={setHotDealsEnabled}
            setSelectedInclusions={setSelectedInclusions}
            onSortProp={sortOptions.find((a) => a.name === selectedSort) as SortBy}
            pricingMax={pricingValues ? roundToNearestTen(Math.max(...pricingValues)) : 0}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            regions={regions}
            categories={categories}
            toggledCatGroups={toggledCatGroups}
            setToggledCatGroups={setToggledCatGroups}
            selectedCatFilters={selectedCatFilters}
            setSelectedCatFilters={setSelectedCatFilters}
            toggledRegionGroups={toggledRegionGroups}
            setToggledRegionGroups={setToggledRegionGroups}
            selectedRegionFilters={selectedRegionFilters}
            setSelectedRegionFilters={setSelectedRegionFilters}
            entityType={entityType}
            userChangedFilterRef={userChangedFilter}
          />
        </GridLeft>
        <GridRight id="productsGrid" ref={productsRef}>
          {renderResults()}
        </GridRight>
      </ListingWrap>
    </>
  )
}

const useOutsideClick = (className: string, callback: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      let element = event.target
      while (element) {
        if (element.classList && element.classList.contains(className)) {
          return
        }
        element = element.parentElement
      }
      callback()
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [className, callback])
}

type FilterDropdownMenuProps = {
  name: 'all filters' | 'categories' | 'regions' | 'price' | 'availability'
  isOpen: boolean
  enabledPlps: ('ALL' | FiltersListingPreviewEntityType)[]
  entityType: FiltersListingPreviewEntityType
  handleOpenFilter: (filterIndex: number) => void
  handleCloseFilter: (filterIndex: number) => void
  hasData: (name: string) => boolean
  renderIndicator: (name: string) => false | React.JSX.Element | undefined
  renderFilterData: (name: string) => React.JSX.Element | undefined
  index: number
}

const FilterDropdownMenu = ({
  name,
  isOpen,
  enabledPlps,
  entityType,
  handleOpenFilter,
  handleCloseFilter,
  hasData,
  renderIndicator,
  renderFilterData,
  index,
}: FilterDropdownMenuProps) => {
  const isMobileView = useIsMobile()
  const panelRef = useRef<HTMLDivElement | null>(null)
  const [mounted, setMounted] = useState(false)

  // Set `mounted` to `true` after the component has mounted on the client side
  // This prevents SSR mismatches by ensuring portals are only rendered on the client
  useEffect(() => {
    setMounted(true)
  }, [])

  // Delay rendering mobile filter panel inside portal for proper animation
  useEffect(() => {
    if (mounted && isMobileView && isOpen) {
      requestAnimationFrame(() => {
        if (panelRef.current) {
          panelRef.current.style.top // Force reflow to ensure smooth animation
        }
      })
    }
  }, [isOpen, isMobileView, mounted])

  const filterDropDownContent = (
    <>
      <FilterBg active={isOpen} />
      <FilterDropdownPanel className="filter-con-inside" active={isOpen}>
        <MobileFilterHeader>
          <Heading size="L">{filterHeading(entityType, name)}</Heading>
          <Close onClick={() => handleCloseFilter(index)} data-testid={`filter-close-${name}`}>
            <Icon name="close" />
          </Close>
        </MobileFilterHeader>
        <PanelContent data-testid={`filter-data-${name}`}>{renderFilterData(name)}</PanelContent>
        <Footer>
          <FooterButtons>
            <Body weight="medium">Clear all</Body>
            <Button onClick={() => handleCloseFilter(index)}>Apply</Button>
          </FooterButtons>
        </Footer>
      </FilterDropdownPanel>
    </>
  )

  return (
    <div>
      {name === 'all filters' ? (
        <FilterDropdownButton
          onClick={() => handleOpenFilter(index)}
          className="all-filters filter-con-inside"
          filterOpen={isOpen}
          data-testid="filter-btn-all"
        >
          <Icon name="filter" />
          <Body size="M" weight="semibold">
            All filters
          </Body>
        </FilterDropdownButton>
      ) : (
        (enabledPlps.includes('ALL') || enabledPlps.includes(entityType)) &&
        hasData(name) && (
          <>
            <FilterDropdownWrap>
              <FilterDropdownButton
                filterOpen={isOpen}
                className="filter-con-inside"
                onClick={() => handleOpenFilter(index)}
                data-testid={`filter-btn-${name}`}
              >
                <FilterDropdownButtonLabel>
                  <Body size="M" weight="semibold">
                    {filterHeading(entityType, name)}
                  </Body>
                  <span>{renderIndicator(name)}</span>
                </FilterDropdownButtonLabel>
              </FilterDropdownButton>
              {/* Use portal for mobile, normal render for desktop */}
              {mounted && isMobileView
                ? createPortal(filterDropDownContent, document.body)
                : isOpen && filterDropDownContent}
            </FilterDropdownWrap>
          </>
        )
      )}
      <div id="bottomPos"></div>
    </div>
  )
}

type RenderFilterButtonsProps = {
  entityType: FiltersListingPreviewEntityType
  regions: WidgetRegionLink[] | undefined
  categories: WidgetCategoryLink[] | undefined
  filterDropdowns: FilterDropdown[]
  setFilterDropdowns: React.Dispatch<SetStateAction<FilterDropdown[]>>
  pricingValues: number[]
  priceRange: number[]
  setPriceRange: React.Dispatch<SetStateAction<number[]>>
  toggledCatGroups: number[]
  setToggledCatGroups: React.Dispatch<SetStateAction<number[]>>
  selectedCatFilters: string[]
  setSelectedCatFilters: React.Dispatch<SetStateAction<string[]>>
  toggledRegionGroups: number[]
  setToggledRegionGroups: React.Dispatch<SetStateAction<number[]>>
  selectedRegionFilters: string[]
  setSelectedRegionFilters: React.Dispatch<SetStateAction<string[]>>
  handleCloseFilter: (filterIndex: number) => void
  handleOpenFilter: (filterIndex: number) => void
  toggleFilterDropdown: (filterIndex: number) => void
}

const RenderFilterButtons = ({
  entityType,
  regions,
  categories,
  filterDropdowns,
  setFilterDropdowns,
  pricingValues,
  priceRange,
  setPriceRange,
  toggledCatGroups,
  setToggledCatGroups,
  selectedCatFilters,
  setSelectedCatFilters,
  toggledRegionGroups,
  setToggledRegionGroups,
  selectedRegionFilters,
  setSelectedRegionFilters,

  handleCloseFilter,
  handleOpenFilter,
  toggleFilterDropdown,
}: RenderFilterButtonsProps) => {
  const hasData = (name: string) => {
    switch (name) {
      case 'categories':
        return Boolean(categories && categories.length)
      case 'regions':
        return Boolean(regions && regions.length)
      default:
        return true
    }
  }

  const renderIndicator = (name: string) => {
    if (name === 'categories') {
      let categoriesLength = selectedCatFilters.length
      return (
        categoriesLength > 0 && <FilterIndicator>{categoriesLength <= 9 ? categoriesLength : '9+'}</FilterIndicator>
      )
    } else if (name === 'price') {
      let rangeLabel = PRICING_FILTERS.find((range) => range.min === priceRange[0] && range.max === priceRange[1])
      return <Body>: {rangeLabel!.label}</Body>
    } else if (name === 'regions') {
      let regionsLength = selectedRegionFilters.length
      return regionsLength > 0 && <FilterIndicator>{regionsLength <= 9 ? regionsLength : '9+'}</FilterIndicator>
    }
  }

  const renderFilterData = (name: string) => {
    switch (name) {
      case 'categories':
        return (
          categories &&
          renderCategoryFilters(
            categories,
            toggledCatGroups,
            setToggledCatGroups,
            selectedCatFilters,
            setSelectedCatFilters
          )
        )
      case 'regions':
        return (
          regions &&
          renderRegionFilters(
            regions,
            toggledRegionGroups,
            setToggledRegionGroups,
            selectedRegionFilters,
            setSelectedRegionFilters
          )
        )
      case 'price':
        return renderPriceFilters(roundToNearestTen(Math.max(...pricingValues)), priceRange, setPriceRange)
      default:
        return
    }
  }

  const roundToNearestTen = (price: number) => {
    return roundDecimalNumber(price, 1)
  }

  return (
    <>
      {filterDropdowns.map(({ name, isOpen, enabledPlps }, index) => (
        <FilterDropdownMenu
          key={name}
          name={name}
          isOpen={isOpen}
          enabledPlps={enabledPlps}
          entityType={entityType}
          handleOpenFilter={handleOpenFilter}
          handleCloseFilter={handleCloseFilter}
          hasData={hasData}
          renderIndicator={renderIndicator}
          renderFilterData={renderFilterData}
          index={index}
        />
      ))}
    </>
  )
}

export default FilterListingPreview
